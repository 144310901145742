@charset "utf-8";

/* ==========================================================================
  Imported Styles
========================================================================== */

@import "variables.scss";
@import "mixins.scss";

/* ==========================================================================
  Utils
========================================================================== */

body {
  font-family: $font_body;
  font-size: 13px;
  line-height: 1.65;
  color: $white;
  background: $bg;
  letter-spacing: 0.8px;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  display: block;
  width: 100%;
}

h1,
h2,
h3,
h4,
li,
p {
  font-size: 1em;
  font-weight: normal;
  text-transform: uppercase;
}

h1 {
  font-size: 14px;
  letter-spacing: 2px;
}

h2 {
  font-size: 16px;
  letter-spacing: 2px;
  margin-top: 20px;
}

a {
  color: currentColor;
  text-decoration: none;
}

hr.short {
  max-width: 120px;
  margin: 30px auto;
}

.spacer {
  height: 1px;
  margin: 10px auto;
}

.underline,
.active {
  text-decoration: underline;
}

.btn {
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 2px;
  padding: 10px 20px;
  border: 1px solid;
  margin-bottom: 20px;
  display: inline-block;
  transition: background-color 0.3s ease-in-out;
  &:hover {
    background-color: rgba($white, 0.2);
  }
  &.hide-on-mobile {
    display: inline-block;
  }
}

.click {
  cursor: pointer;
}

.center-text {
  text-align: center;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.logo {
  @include background-image;
  background-image: url("../images/logo.svg");
  width: 220px;
  height: 60px;
}

// Navigation
.navigation {
  text-align: center;
  position: fixed;
  width: 100%;
  background-color: $bg;
  padding: 30px 20px 10px;
  z-index: 2;
  box-sizing: border-box;
  ul {
    padding: 0;
    li {
      list-style: none;
      display: inline-block;
      margin: 0 20px;
    }
  }
  &.hidden {
    visibility: hidden;
  }
}

.secondary-menu {
  background-color: $white;
  color: $bg;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  position: fixed;
  width: 100%;
  top: -320px;
  z-index: 1;
  transition: top 0.3s ease-in-out;
  ul {
    padding: 0;
    li {
      list-style: none;
      margin-bottom: 10px;
    }
  }
  &.show-menu {
    top: 80px;
  }
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 140px;
  padding-bottom: 20px;
  background-color: $bg;
  .footer-description {
    position: absolute;
    text-align: center;
    width: 100%;
    left: calc(50% - 50%);
    top: 0px;
    box-sizing: border-box;
  }
  &.relative {
      position: relative;
  }

  .sister-brands {
    position: absolute;
    text-align: center;
    width: 100%;
    left: calc(50% - 50%);
    bottom: 20px;
    box-sizing: border-box;
    p {
      font-size: 11px;
      margin: 0;
    }
  }

  .footer-signup_social,
  .footer-signup_newsletter {
    position: absolute;
    bottom: 20px;
    z-index: 3;
  }
  .footer-signup_social {
    left: 50px;
  }
  .footer-signup_newsletter {
    right: 50px;
  }

  .instagram-icon {
    @include background-image;
    background-image: url("../images/instagram.svg");
    width: 20px;
    height: 20px;
  }
}

.cookie-notice {
  position: fixed;
  width: 100%;
  bottom: 100px;
  background-color: rgba($white, 0.2);
  font-size: 10px;
  text-align: center;
  p {
    line-height: 2.4;
    max-width: 920px;
    margin: 0 auto;
    padding: 20px;
  }
  &.md-hide {
    display: none;
  }
}

.btn-tiny {
  border: 1px solid;
  line-height: 1;
  font-size: 9px;
  padding: 5px;
  display: inline-block;
  cursor: pointer;
  margin-left: 8px;
}

// Pages
.container {
  min-height: calc(100vh - 200px);
}
.page {
  min-height: calc(100vh - 200px);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-top: 90px;
  flex-direction: column;
  &.static-page {
    padding-bottom: 100px;
  }
}

.reservations {
    padding-top: 160px;
    padding-bottom: 240px;
}

.logo-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  flex-direction: row;
  margin-top: 10px;
}

.asap {
  @include background-image;
  width: 100px;
  height: 35px;
  background-image: url("../images/asap_pizza.png");
}
.lyles {
  @include background-image;
  width: 100px;
  height: 25px;
  background-image: url("../images/lyles.png");
}

.text-block {
  -webkit-box-flex: 0;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
  max-width: 720px;
  width: 100%;
  text-align: center;
  line-height: 1.8;
  h1 {
    flex: 1;
  }
  h2, a {
    text-decoration: underline;
  }
  .menu-link {
    display: block;
  }
}

.flor-static {
  max-width: 720px;
  width: 100%;
  position: relative;
  margin: 0 auto;
  padding: 50px 20px;
  .flor-static_block {
    margin-bottom: 50px;
    .flor-static_block-text {
      margin-top: 20px;
      margin-bottom: 30px;
      h2 {
        margin-bottom: 10px;
      }
    }
  }
  .columns {
    display: inline-block;
    float: left;
    margin: 0 1.5%;
    &.three {
      width: 22%;
    }
    &.four {
      width: 30.3333333333%;
    }
    &.seven {
      width: 56.6666666667%;
    }
  }
  .row {
    float: left;
    width: 100%;
    border-bottom: 1px dashed $aluminum;
    padding: 20px 0;
  }
}

.text-link {
    padding-bottom: 20px;
    text-decoration: underline;
}

iframe {
  padding: 50px 0 20px;
  width: 520px;
  max-width: 100%;
  height: 500px !important;
  border: 0;
}

// Modal
.modal-wrapper {
  .modal,
  .alert {
    position: fixed;
    box-sizing: border-box;
    z-index: 100;
    background-color: $bg;
    border-radius: 2px;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 30px;
    top: 100vh;
    // transform: scale(0);
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .alert {
    position: fixed;
    max-height: 580px;
    width: 720px;
    top: calc(50vh - 290px);
    left: calc(50vw - 360px);
    z-index: 3;
    opacity: 0;
    animation: load 0.5s ease-in-out forwards 1.8s;
    .logo {
      height: 25px;
    }
  }
  .overlay {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 2;
    opacity: 0;
    animation: load 0.5s ease-in-out forwards 1s;
  }
  .close-trigger {
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 100;
    cursor: pointer;
  }
  .navigation {
    position: absolute;
    top: 0;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
  }

  &.md-show {
    visibility: visible;
    .modal {
      transition: opacity 0.5s ease-in-out;
      top: 0;
      opacity: 1;
    }
  }
  &.md-hide {
    visibility: hidden;
    .modal {
      opacity: 0;
    }
  }
}

.nav-link {
    margin-bottom: 10px;
    display: block;
}

.nav-button {
    padding: 3px 6px;
    background-color: rgba(255,255,255,.2);
}

form {
  input {
    border: 0;
    border-bottom: 1px solid $white;
    color: $white;
    width: 320px;
    max-width: 100%;
    box-sizing: border-box;
    padding: 5px 10px;
    text-transform: uppercase;
    font-size: 13px;
    font-family: $font_body;
    background-color: $bg;
  }
  ::placeholder {
    color: rgba($white, 0.6);
  }
  button {
    margin: 0 5px;
    border: 1px solid $white;
    background-color: $bg;
    color: $white;
    padding: 5px 10px;
    font-family: $font_body;
    text-transform: uppercase;
    font-size: 13px;
  }
  *:focus {
    outline: none;
  }
}

@keyframes load {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

body {
  opacity: 0;
  animation: load 0.5s 0.5s ease-in-out forwards;
}

.logo {
  opacity: 0;
  animation: load 0.8s 1s ease-in-out forwards;
}

.page {
  opacity: 0;
  animation: load 0.3s 0.5s ease-in-out forwards;
}



/* Page Loader */
.loader::before {
  content: "";
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background: red;
}

.loader::after {
  content: "";
  position: fixed;
  z-index: 100000;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  margin: -15px 0 0 -15px;
  pointer-events: none;
  opacity: 0.4;
  background: $green;
  transform-origin: 50% 100%;
  transform: rotate3d(0, 0, 1, 45deg);
  animation: loaderAnim 0.7s linear infinite alternate forwards;
}

@keyframes loaderAnim {
  to {
    opacity: 1;
    transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 0, 1)
      rotate3d(0, 0, 1, -180deg);
  }
}

@import "responsive.scss";
