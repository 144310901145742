/* ==========================================================================
  Responsive stylesheets
========================================================================== */

@import "variables.scss";
@import "mixins.scss";

/* ==========================================================================
  Mobile styles
========================================================================== */

.show-on-mobile {
  display: none;
}

.hide-on-mobile {
  display: block;
}

@include breakpoint(smartphone) {
  .hide-on-mobile {
    display: none !important;
  }

  .show-on-mobile {
    display: block;
    &.btn {
      display: inline-block;
    }
  }

  // Navigation
  nav.navigation {
    padding-top: 30px;
    ul {
      margin: 0;
    }
    li {
      margin-left: 0;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
  .nav-button {
      padding: 3px 6px;
      background-color: rgba(86, 104, 106, .2);
      display: inline-block;
      margin: 0;
  }
  .page {
    padding-top: 70px !important;
  }
  .footer {
    height: 150px;

    .footer-description {
      padding: 0 20px;
      box-sizing: border-box;
      font-size: 11px;
    }
  }

  .logo-block {
    // flex-direction: column;
  }
  .modal-wrapper {
    .modal {
      padding: 10px;
      .navigation {
        top: auto;
        bottom: 20px;
        ul {
          li {
            display: block;
            margin: 0 0 10px;
          }
        }
      }
    }
    .alert {
      width: 100% !important;
      left: 0% !important;
      height: 100% !important;
      max-height: 100% !important;
      top: 0% !important;
      padding: 30px 10px !important;
      display: block !important;
      text-align: center;
      overflow: auto;
    }
  }

  .text-block {
    margin-bottom: 60px;
    p {
      padding: 0 20px;
    }
  }

  .logo {
    width: 180px;
    height: 40px;
  }

  form {
    button {
      display: block;
      margin: 20px auto;
    }
  }

  .page.reservations {
    min-height: 100vh;
    display: block;
    padding-bottom: 150px;
    .text-block {
      margin-top: 30px;
      margin-bottom: 100px;
    }
  }
}
