/* ==========================================================================
  Mixins
========================================================================== */


@mixin vertical-align {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.vert-align {
    @include vertical-align;
}

@mixin background-image {
    display: inline-block;
    text-indent: -9999px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

@mixin transition($object, $size) {
    -webkit-transition: $object $size ease-in-out;
    -khtml-transition:  $object $size ease-in-out;
    -moz-transition: 	$object $size ease-in-out;
    -ms-transition: 	$object $size ease-in-out;
    -o-transition: 		$object $size ease-in-out;
    transition: 		$object $size ease-in-out;
}


@keyframes fadeInText {
    from { opacity: 0; top: 55%;}
    to { opacity: 1;  top: 48%;}
}

@keyframes fadeInImage {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes zoomInImage {
    from { transform: scale(.98); }
    to { transform: scale(1); }
}

@keyframes zoomHoverImage {
    from { transform: scale(1); }
    to { transform: scale(1.4); }
}

@keyframes moveUp {
    from { -webkit-transform: translate3d(0,5px,0);
    transform: translate3d(0,5px,0); }
    to {-webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);}
}

@keyframes moveUpMore {
    from { -webkit-transform: translate3d(0,20px,0);
    transform: translate3d(0,20px,0); }
    to {-webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);}
}


@mixin breakpoint($media) {

  //Narrow Browser Windows (between 0 and 679 pixels) and Smartphones in both orientations
  @if $media == smartphone {
    @media only screen and (min-width: 0px) and (max-width: 768px), (device-aspect-ratio: 40/71)  { @content; }
  }

  //Medium-Wide Browser Windows
  @if $media == small-browser {
    @media only screen and (min-width : 769px) and (max-width : 1114px) { @content; }
  }

  //Medium Browser Windows and Portrait Tablets
  @if $media == normal-browser {
    @media only screen and (min-width: 1115px) and (max-width: 1280px) { @content; }
  }

  @if $media == medium-wide-browser {
    @media only screen and (min-width: 1281px) and (max-width: 1599px) { @content; }
  }

  //Medium Browser Windows and Portrait Tablets
  @if $media == wide-browser {
    @media only screen and (min-width: 1600px) { @content; }
  }

}
